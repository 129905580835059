export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },
  getUsageData(state) {
    return state.usage;
  },
  getUsageTotal(state) {
    return state.usageTotal;
  },
  getDiscountOns(state) {
    return state.discountOns;
  },
  getDiscountOnTypes(state) {
    return state.discountOnTypes;
  },

  getApplyFor(state) {
    return state.applyFor;
  },


  getConditions(state) {
    return state.conditions;
  },


  getDetail(state) {
    return state.detail;
  },
  getProducts(state) {
    return state.products;
  },

  getUsers(state) {
    return state.users;
  },


  getDiscountInfo(state) {
    return state.discountInfo;
  }

};
