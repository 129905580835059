
import store from '@/store';

/**
 * Checks if the user has the required permissions.
 * If the user does not have the required permissions, the element is removed from the DOM.
 *
 * @param {HTMLElement} el - The element to check permissions for.
 * @param {VNodeDirective} binding - A Vue directive object. The required permissions are passed as the value of the directive.
 */
function checkPermission(el, binding) {
    const { value } = binding// the permission


    const roles = store.getters && store.getters['profile/getPermissions']

    if (value && value instanceof Array) {
        if (value.length > 0) {
            const permissionRoles = value

            // Check if the user has any of the required permissions
            const hasPermission = roles.some(role => {
                return permissionRoles.includes(role)
            })

            // If the user does not have the required permissions, remove the element from the DOM
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        }
    }
}

export default {
    /**
     * Called when the bound element has been inserted into its parent node.
     *
     * @param {HTMLElement} el - The element the directive is bound to.
     * @param {VNodeDirective} binding - A Vue directive object.
     */
    inserted(el, binding) {
        checkPermission(el, binding)
    },

    /**
     * Called after the containing component’s VNode and the VNodes of its children have updated.
     *
     * @param {HTMLElement} el - The element the directive is bound to.
     * @param {VNodeDirective} binding - A Vue directive object.
     */
    update(el, binding) {
        checkPermission(el, binding)
    }
}