/**
 * Vuex actions for managing reviews.
 * @module store/reviews/actions
 */
export default {
  /**
   * Fetch reviews based on filters.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for fetching reviews.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`reviews${filters}`)
      .then((response) => {
        if (response.status === 200) {

          commit('setData', response.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggle review status.
   * @async
   * @function toggle
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the review to toggle.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`reviews/toggle`, data)
      .then(response => {
        if (response.status === 200) {

          commit('updateData', response.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Export reviews to Excel.
   * @async
   * @function exportExcel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing type for exporting reviews.
   */
  async exportExcel({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`reviews/export-excel`, { responseType: 'blob' })
      .then(respo => {
        if (respo.status === 200) {
          // commit('setData', respo.data.data);
          const url = window.URL.createObjectURL(new Blob([respo.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', data.type + '.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
