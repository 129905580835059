export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    setDetail: (state, data) => {
        state.detail = data;
    },

};
