export default {
  /**
   * Fetches service fees data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`service-fees${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the status of a service fee on the server and commits the updated service fee data to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`service-fees/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new service fee on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`service-fees/create`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        if (error.response.status === 402) {
          commit('failMessage', 'service_fee_exits', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates an existing service fee on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`service-fees/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {


        if (error.response.status === 402) {
          commit('failMessage', 'service_fee_exits', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a service fee from the server and removes it from the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`service-fees/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the types of services for which fees can be applied from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getApplyFor({ commit }) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`service-fees/apply_for`)
      .then(response => {
        if (response.status === 200) {
          commit('setApplyFor', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
