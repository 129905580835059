export default {
  /**
   * Fetches notifications from the server and commits it to the store.
   * If the request is successful, it unsets the previous notifications, sets the new data, and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`notifications${filters}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('unsetNotifications');

          setTimeout(() => {
            commit('setData', respo.data.data);
          }, 0);


          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a notification from the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`notifications/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new notification on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`notifications/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the detail of a notification from the server and commits it to the store.
   * If the request is successful, it sets the detail data and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async detail({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`notifications/detail/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('setDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });
          return respo.data.data;
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
