export default {
    getSubscribe(state) {
        return state.subscribe;
    },
    getRooms(state) {
        return state.rooms;
    },
    getRoomsForFirstTime(state) {
        return state.roomsForFirstTime;
    },
    getActiveRoom(state) {
        return state.activeRoom;
    },

    getRoomIds(state) {
        return state.roomIds;
    },

    getRoomMessages(state) {
        return state.roomMessages;
    },
    getRoomMessagesForFirstTime(state) {
        return state.roomMessagesForFirstTime;
    }
};
