export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    unsetProducts: (state) => {
        state.products = [];
    },

    setProducts: (state, data) => {
        state.products = data;
    },


    setProductDetail: (state, data) => {
        state.productDetail = data;
    },

    updateStatusData: (state, data) => {
        let temp = state.data;
        state.data = temp.map((item) => item.id === data.id ? {...item,status: data.status} : item)
    },

    deleteItem: (state, id) => {
        let data = state.data;
        state.data = data.filter((item) => item.id !== id)
    },


    setCategories: (state, data) => {
        state.categories = data;
    },


    setDetail: (state, data) => {
        state.detail = data;
    },
    unsetDetail: (state) => {
        state.detail = {
            sizes: [],
            types: [],
            addons: [],
        };
    },

};
