import i18n from '@/libs/i18n';
import router from '@/router';

/**
 * Vuex actions for managing vendor packages.
 * @module store/vendor/packages/actions
 */
export default {
  /**
   * Fetch vendor packages based on filters.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for fetching vendor packages.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/packages${filters}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('unsetProducts');
          setTimeout(() => {
            commit('setData', respo.data.data);
          }, 0);


          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Export vendor packages to Excel.
   * @async
   * @function exportExcel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for exporting vendor packages.
   */
  async exportExcel({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/packages/export-excel`, { responseType: 'blob' })
      .then(respo => {
        if (respo.status === 200) {
          const url = window.URL.createObjectURL(new Blob([respo.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'products.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Import vendor packages from Excel.
   * @async
   * @function importExcel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the Excel file to import.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async importExcel({ commit }, data) {
    commit('loadingStart', null, { root: true });


    return await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/packages/import-excel`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
          return true;
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vendor package details.
   * @async
   * @function detail
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the vendor package to fetch.
   */
  async detail({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/packages/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDetail', respo.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vendor products.
   * @async
   * @function getVendorProducts
   * @param {Object} context - Vuex action context.
   * @param {string} vendor_id - The id of the vendor.
   */
  async getVendorProducts({ commit }, vendor_id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`users/vendors/${vendor_id}/packages/products`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setProducts', respo.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Unset vendor package details.
   * @async
   * @function unsetDetail
   * @param {Object} context - Vuex action context.
   */
  async unsetDetail({ commit }) {
    commit('unsetDetail');
  },

  /**
   * Fetch vendor product details.
   * @async
   * @function getVendorProductDetail
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the vendor product to fetch.
   */
  async getVendorProductDetail({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/packages/products/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setProductDetail', respo.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Reorder vendor packages.
   * @async
   * @function reorder
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the new order of vendor packages.
   */
  async reorder({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/packages/reorder/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggle vendor package status.
   * @async
   * @function toggle
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the vendor package to toggle.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/packages/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {


          commit('updateStatusData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Create a new vendor package.
   * @async
   * @function create
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the new vendor package.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/packages/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'users-vendors-packages', params: { lang: i18n.locale, id: data.vendor_id } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update a vendor package.
   * @async
   * @function update
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the vendor package to update.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/packages/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'users-vendors-packages', params: { lang: i18n.locale, id: data.vendor_id } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Delete a vendor package.
   * @async
   * @function delete
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the vendor package to delete.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/packages/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vendor package categories.
   * @async
   * @function getCategories
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the vendor.
   */
  async getCategories({ commit }, data) {

    await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/packages/categories`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setCategories', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
