export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },
  getDetail(state) {
    return state.detail;
  },

  getServices(state) {
    return state.services;
  }
};
