export default {
  getData(state) {
    return state.data;
  },

  getDetail(state) {
    return state.detail;
  },

  getTotal(state) {
    return state.total;
  },

  getVendors(state) {
    return state.vendors;
  },

  getVendorTypes(state) {
    return state.vendorTypes;
  },


  getRedirects(state) {
    return state.redirects;
  }

};
