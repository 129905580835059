export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },

  getVendors(state) {
    return state.vendors;
  },


  getRedirects(state) {
    return state.redirects;
  },

  getCategories(state){
    return state.categories;
  }

};
