export default {

  getAddresses(state) {
    return state.addresses;
  },
  getTotal(state) {
    return state.total;
  },


  getDetail(state) {
    return state.detail;
  },

  getProducts(state) {
    return state.products;
  },


  getVehicleTypes(state) {
    return state.vehicleTypes;
  },

  getProductDetail(state) {
    return state.productDetail;
  },

  getPriceDetail(state) {
    return state.priceDetail;
  },

  getCart(state) {
    return state.cart;
  },
  getDeleteOrders(state) {
    return state.deletedOrders;
  },


  getUsers(state) {
    return state.users;
  },

  getVendors(state) {
    return state.vendors;
  },

  getOrderTypes(state) {
    return state.orderTypes;
  }
};
