import Vue from 'vue';
import i18n from '@/libs/i18n';
import router from '@/router';
import ChatToastification from '@/components/ChatToastification.vue';

export default {
  subscribe: (state, data) => {
    state.subscribe = data;
  },
  setRoomIds: (state, data) => {
    state.roomIds = data;
  },
  setRooms: (state, data) => {
    state.rooms = [...data];
  },
  clearRoomMessages: (state) => {
    state.roomMessages = [];
  },

  setRoomMessages: (state, data) => {

    state.roomMessages = [...data];

    // data.map((message) => {
    //     if (state.roomMessages.every((_msg) => _msg._id != message._id)) {
    //         state.roomMessages.push(message)
    //     }
    //
    // })
  },
  pushRoomMessages: (state, data) => {

    if (state.roomMessages.every((message) => message._id !== data._id))
      state.roomMessages.push(data);

  },
  setNewRoom: (state, data) => {
    //show notification
    try{

    var date = data[0]['index'];// ex:Mon Jun 03 2024 11:13:43 GMT+0300 (Arabian Standard Time)
    // if the date is more than 5 minutes ago then don't show the notification
    var now = new Date();
    var diff = now - date;
    var diffMinutes = Math.floor(diff / 60000);
    if (diffMinutes > 5) {
      return;
    }
    }catch(e){
      return;
    }


    for (const room of data) {
      Vue.$toast(
        {
          component: ChatToastification,
          props: {
            orderId: room.id,
            title: room.roomName,
            btn: i18n.t('common.show_details'),
            label: i18n.t('common.new_chat'),
            body: room.lastMessage.content
          },
          listeners: {
            goToDetail: () => {
              if (router.currentRoute.name !== 'chat') {
                router.push({
                  name: 'chat',
                  params: { lang: i18n.locale },
                  meta: {
                    room: room.id
                  }
                });
                state.activeRoom = room;
              } else {
                //update room meta
                state.activeRoom = room;
              }
            }  // Optional
          }
        },
        {
          toastClassName: 'new-chat-notification',
          position: 'bottom-right',
          timeout: 3000

        }
      );
    }

  },
  setActiveRoom: (state, data) => {
    state.activeRoom = data;
  },
  setRoomsForFirstTime: (state, data) => {
    state.roomsForFirstTime = data;
  }
};
