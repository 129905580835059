/**
 * Vuex actions for managing vendor work days.
 * @module store/vendor/workDays/actions
 */
export default {
  /**
   * Create a new work day for a vendor.
   * @async
   * @function create
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the new work day.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/work-days/create`, data)
      .then((response) => {
        if (response.status === 200) {

          commit('setData', response.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {

        if (error.response.status === 405) {
          commit('failMessage', 'max_work_shifts', { root: true });
        }

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch work days for a vendor.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the vendor.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/work-days`)
      .then((response) => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggle a work day for a vendor.
   * @async
   * @function toggle
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the work day to toggle.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/work-days/toggle`, data)
      .then((response) => {
        if (response.status === 200) {

          commit('setData', response.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Delete a work day for a vendor.
   * @async
   * @function delete
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the id of the work day to delete.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/work-days/delete/${data.id}`)
      .then((response) => {
        if (response.status === 200) {

          commit('setData', response.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
