import router from '@/router';
import i18n from '@/libs/i18n';

export default {
  /**
   * Fetches system users from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`system-users${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches chat admins from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getChatAdmins({ commit }) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`system-users/chats`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setAllData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the detail of a system user from the server and commits it to the store.
   * If the request is successful, it sets the detail data and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async getDetail({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`system-users/detail/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDetail', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Unsets the detail of a system user from the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async unsetDetail({ commit }) {

    commit('unsetDetail');
  },

  /**
   * Fetches roles from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getRoles({ commit }) {

    await this._vm.$http
      .get(`system-users/roles`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setRoles', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the state of a system user on the server.
   * If the request is successful, it updates the state of the system user in the store, shows a success message, and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`system-users/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new system user on the server.
   * If the request is successful, it redirects to the system users page and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`system-users/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: `system-users`, params: { lang: i18n.locale } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates a system user on the server.
   * If the request is successful, it redirects to the system users page and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`system-users/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: `system-users`, params: { lang: i18n.locale } });

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Resets the password of a system user on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The id of the system user.
   * @returns {Promise} - The promise of the HTTP request.
   */
  async resetPassword({ commit }, id) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`system-users/reset-password/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
        return respo.data.data;
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a system user from the server.
   * If the request is successful, it shows a success message, stops the loading state, and removes the system user from the store.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`system-users/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
