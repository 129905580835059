<template>
  <div class="toastification text-right">
    <div class="d-flex flex-grow-1 align-items-center">
      <span class="new-chat-notification_title">
      {{label}}
      </span>
      <span
          class="cursor-pointer toastification-close-icon mr-auto"
          @click="$emit('close-toast')"
      >
        <close-icon/>
      </span>
    </div>
    <div class="new-chat-notification_body">
      <span style='font-weight: bold;color:black'>{{ title }}</span>
      <br>
      {{ body }}
    </div>
    <div @click="detail" class="new-chat-notification_btn">
      {{ btn }}
    </div>
  </div>
</template>

<script>

export default {

  props: {

    orderId: {
      type: [String, Number],
      default: null,
    },
    orderNo: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },

    body: {
      type: String,
      default: null,
    },

    btn: {
      type: String,
      default: null,
    },


  },


  methods: {
    detail() {
      this.$emit('goToDetail')
      setTimeout(() => {

        this.$emit('close-toast');
      }, 100);
    },
  },
};
</script>
