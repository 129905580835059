import discounts from './discounts';
import discountsVendor from './discountsVendor';
import users from './users';
import notifications from './notifications';
import fees from './fees';
import branches from './branches';
import settings from './settings';
import ads from './ads';
import assignOrders from './assignOrders';
import home from './home';
import reports from './reports';
import financial from './financial';
import newOrder from './newOrder';
import featured from './featured';

export default {
  modules: {
    reports,
    home,
    assignOrders,
    ads,
    financial,
    settings,
    branches,
    fees,
    notifications,
    users,
    discounts,
    discountsVendor,
    newOrder,
    featured

  },
  namespaced: true
};
