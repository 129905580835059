export default {
  setData: (state, data) => {
    state.data = data.content;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page
    };
  },
  unsetData: (state) => {
    state.data = [];
    state.total = {
      totalItems: 0,
      totalPages: 0
    };
  },


  updateData: (state, data) => {

    let temp = state.data;
    state.data = temp.map((item) => item.id === data.id ? {
      ...item,
      status: data.status
    } : item);
  },


  deleteItem: (state, id) => {
    let data = state.data;
    state.data = data.filter((item) => item.id !== id);
  },


    setVehicleTypes: (state, data) => {
        state.vehicleTypes = data;
    },
    setAddressTypes: (state, data) => {
        state.addressTypes = data;
    },
    setVendorCategories: (state, data) => {
        state.vendorCategories = data;
    },
    setBranches: (state, data) => {
        state.branches = data;
    },
    setVendorClassification: (state, data) => {
        state.vendorClassification = data;
    },


  setDetail: (state, data) => {
    state.simpleDetail = data;
  },
  unsetDetail: (state) => {
    state.simpleDetail = {};
  },
  //platformBoundaries
  setPlatformBoundaries: (state, data) => {
    state.platformBoundaries = data;
  }

};
