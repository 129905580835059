import router from '@/router';
import i18n from '@/libs/i18n';

export default {
  /**
   * Fetches system roles from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`system-roles${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the detail of a system role from the server and commits it to the store.
   * If the request is successful, it sets the detail data and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async getDetail({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`system-roles/detail/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDetail', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Unsets the detail of a system role from the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async unsetDetail({ commit }) {

    commit('unsetDetail');
  },

  /**
   * Fetches permissions from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getPermissions({ commit }) {

    await this._vm.$http
      .get(`system-roles/permissions`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setPermissions', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the state of a system role on the server.
   * If the request is successful, it updates the state of the system role in the store, shows a success message, and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`system-roles/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        if (error.response.status === 405) {
          commit('failMessage', 'toggle_role', { root: true });
        } else {

          commit('failMessage', 'toggle', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new system role on the server.
   * If the request is successful, it redirects to the system roles page and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`system-roles/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: `system-roles`, params: { lang: i18n.locale } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates a system role on the server.
   * If the request is successful, it redirects to the system roles page and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`system-roles/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: `system-roles`, params: { lang: i18n.locale } });

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        if (error.response.status === 405) {
          commit('failMessage', 'update_role', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a system role from the server.
   * If the request is successful, it shows a success message, stops the loading state, and removes the system role from the store.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`system-roles/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {
        if (error.response.status === 405) {
          commit('failMessage', 'delete_role', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
