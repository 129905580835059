export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    setDiscountOns: (state, data) => {
        state.discountOns = data.ons;
        state.discountOnTypes = data.on_types;
    },


    setApplyFor: (state, data) => {
        state.applyFor = data;
    },

    updateData: (state, data) => {
        let temp = state.data;
        state.data = temp.map((item) => item.id === data.id ? data : item)
    },

    setConditions: (state, data) => {
        state.conditions = data;
    },

    deleteItem: (state, id) => {
        let data = state.data;
        state.data = data.filter((item) => item.id !== id)
    },


    setDetail: (state, data) => {
        state.detail = data;
    },
    setProducts: (state, data) => {
        state.products = data;
    },
    setUsers: (state, data) => {
        state.users[0].children = data;
    },
    setDiscountInfo: (state, data) => {
        state.discountInfo = data;
    },
    unsetDiscountInfo: (state) => {
        state.discountInfo = {
            apply_for: []
        };
    },


    setDiscountUsage: (state, data) => {
        state.usage = data.content;
        state.usageTotal = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    unsetDetail: (state) => {
        state.detail = {
            sizes: [],
            types: [],
            addons: [],
        };
    },


};
