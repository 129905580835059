export default {
  /**
   * Fetches vehicles from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`vehicles${filters}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('unsetVehicles');
          setTimeout(() => {
            commit('setData', respo.data.data);
          }, 0);


          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  //get services
  async getServices({ commit },) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`vehicles/get-services`)
      .then(respo => {
        if (respo.status === 200) {

            commit('setServices', respo.data.data);


          commit('loadingFinish', null, { root: true });
        }
      }
      )
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the state of a vehicle on the server.
   * If the request is successful, it updates the state of the vehicle in the store, shows a success message, and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`vehicles/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new vehicle on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`vehicles/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the detail of a vehicle from the server and commits it to the store.
   * If the request is successful, it sets the detail data and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   * @returns {Object} - The detail data of the vehicle.
   */
  async detail({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`vehicles/detail/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('setDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });
          return respo.data.data;
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates a vehicle on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`vehicles/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Reorders vehicles on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async reorder({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`vehicles/reorder/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a vehicle from the server.
   * If the request is successful, it shows a success message, stops the loading state, and removes the vehicle from the store.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`vehicles/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
