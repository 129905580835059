export default {
  getFilterData(state) {
    return state.filterData;
  },

  getVendorCategories(state) {
    return state.vendorCategories;
  },

  getVendorsDropdown(state) {
    return state.vendorsOnCategory;
  },
};
