export default {

  /**
   * Fetches vendor data from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async getVendors({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);
    await this._vm.$http
      .get(`orders/get-vendors${filters}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setVendors', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Creates a new order with default values and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async newOrder({ commit }) {

    let data = {
      'id': 0,
      'no': 0,
      'note': null,
      'userId': 8,
      'driverId': null,
      'cancel_note': null,
      'date': '2023-11-01 11:49:43',
      'service_type_id': 0,
      'vehicle_name_id': 0,
      'vendorId': 0,
      'vendor_name': '',
      'show_driver': 0,
      'from_address': null,
      'to_address': null,
      'order_price': 2500,
      'service_fee': 0,
      'delivery_price': 12600,
      'discount_price': 3000,
      'status_code': 'CN1',
      'status_name': '\u0642\u064a\u062f \u0627\u0644\u0645\u0631\u0627\u062c\u0639\u0629',
      'status_type': 0,
      'status_color': '00C0F3',
      'order': [],
      'tableFields': [
        'index',
        'name',
        'options',
        'quantity',
        'price',
        'total'
      ],
      'vehicle_type': '\u0645\u062a\u0631',
      'bill': {
        'order': {
          'price': 0,
          'dis_price': 0
        },
        'service': {
          'price': 0,
          'dis_price': null
        },
        'delivery': {
          'price': 0,
          'dis_price': 0
        },
        'coupon': {
          'code': 0,
          'price': 0,
          'delivery_discount': 0,
          'order_discount': 0
        },
        'discount': {
          'dis_price': 0
        }
      },
      'total_price': 0,
      'review': null,

      'can_update_addresses': true,
      'can_edit_order': true,
      'can_assign_driver': false,
      'images': [],
      'payment': {}
    };
    commit('setDetail', data);
  },

  /**
   * Fetches user data from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getUsers({ commit }) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/get-users`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setUsers', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches order types from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getOrderTypes({ commit }) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/get-order-types`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setOrderTypes', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches user addresses from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {number} id - The ID of the user to fetch addresses for.
   */
  async getAddresses({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/get-users/addresses/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setAddresses', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches vendor products from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {number} id - The ID of the vendor to fetch products for.
   */
  async getVendorProducts({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/get-vendor-products/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setProducts', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches vehicle types from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getVehicleTypes({ commit }) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/info/vehicle-types`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setVehicleTypes', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches product detail from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {number} id - The ID of the product to fetch detail for.
   */
  async productDetail({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/product-detail/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('vendor/packages/setProductDetail', respo.data.data, { root: true });
          commit('setProductDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates user address on the server and commits the updated data to the store.
   * If the request is successful, it commits the updated data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async updateUserAddress({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/update-address/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Removes a product from the cart and updates the order price.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data of the product to remove.
   */
  async removeFromCart({ commit }, data) {
    commit('removeProductFromOrder', data);
    commit('removeProductFromCart', data);
    commit('updateOrderPrice');
  },

  /**
   * Updates the cart with the given data and updates the order price.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to update the cart with.
   */
  async updateCart({ commit }, data) {
    commit('updateProductOrder', data);
    commit('updateCart', data);
    commit('updateOrderPrice');
  },

  /**
   * Unsets the cart and updates the order price.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async unsetCart({ commit }) {
    // commit('unsetProductOrder');
    commit('unsetCart');
    commit('updateOrderPrice');
  },

  /**
   * Creates a new order on the server with the given data.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async createOrder({ commit, getters }, data) {
    commit('loadingStart', null, { root: true });
    let cart = getters['getCart'];
    await this._vm.$http
      .post(`orders/create-order`, { products: cart, ...data })
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches delivery price from the server with the given data.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async getDeliveryPrice({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`orders/get-delivery-price`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches order price from the server with the given data.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async getOrderPrice({ commit, getters }, data) {
    commit('loadingStart', null, { root: true });
    let cart = getters['getCart'];
    await this._vm.$http
      .post(`orders/get-order-price`, { products: cart, ...data })
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderPriceDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
