export default {
  getData(state) {
    return state.data;
  },

  getVendorsList(state) {
    return state.vendorsList;
  },
  getServicesList(state) {
    return state.servicesList;
  },

  getTopClientsVendor(state) {
    return state.topClientsVendor;
  }
};
