import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';

Vue.component(FeatherIcon.name, FeatherIcon);

window.mapFilterData = function mapFilters(data) {

  //check if the data is empty or null
  if (!data)
    return '';

  let filters = '?';

  for (const [key, value] of Object.entries(data)) {
    if (value) {
      filters += `${key}=${value}&`;
    }
  }

  return filters.slice(0, -1);
}
;
