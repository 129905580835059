<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('financial.operations.add') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="6">
          <text-input
              id="package-ad"
              v-model="form.amount"
              :label="$t('financial.operations.amount')"
              :placeholder="$t('form.text.placeholder')"
              rules="required"
              is-append
              type="number"
              :append-text="$t('common.rial')"
          />
        </b-col>


        <b-col md="6">
          <wameed-dropdown
              v-model="form.type"
              :label="$t('financial.operations.type.title')"
              :placeholder="$t('financial.operations.type.placeholder')"
              variant="disable"
              :items="operations"
              title="name"
              class="wameed_dropdown"

              :no_options="$t('common.no_options')"
              @input="onTypeChange"
          />
        </b-col>
        <b-col md="12">
          <wameed-text-area
              v-model="form.note"
              :label="$t('financial.operations.note')"
              :placeholder="$t('form.text.placeholder')"
              rows="1"
          />
        </b-col>


      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: Object,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },

  },

  data() {
    return {
      operations: [
        {id: '+', name: this.$i18n.t('financial.operations.type.plus')},
        {id: '-', name: this.$i18n.t('financial.operations.type.minus')},
      ],
      form: {
        amount: '',
        type: '',
        note: ''
      },
      showSuccessModal: false,
    };
  },
  computed: {


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
      } else {
        this.form.name = '';
      }
    },
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData
    }
  },
  methods: {
    ...mapActions({
      create: "admin/financial/addOperation",
    }),
    uploadProfileImage(value) {
      this.form.image = value;
    },

    onTypeChange(type) {
      this.form.vendor = '';

    },
    submitOrder() {

      this.form.operation_type = this.form.type.id;
      this.form.user_id = this.$route.params.id
      if (this.userId != null) {
        this.form.user_id = this.userId
      }

      this.create(this.form).then(() => {
        this.$emit('onComplete', true);
        this.closeModal();
        this.$store.commit('loadingFinish', null, {root: true});
      });

    },


    closeModal() {
      this.form = {
        amount: '',
        type: '',
        note: ''
      };
      this.show = false;
    },
  },
};
</script>
