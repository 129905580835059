// import './libs/axios'
import moment from "moment";

require('./libs/axios');
import Vue from 'vue';
import {BootstrapVue, BootstrapVueIcons, ModalPlugin, ToastPlugin} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';
import permission from './directive/permissions';
import './assets/svg/index';
import './assets/images/pages/index';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/vue-cookies';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/quill-editor';
import '@/libs/sweet-alerts';
import '@/libs/floating-vue';
// import '@/libs/vue-select';
import '@/libs/vue-debounce';
import '@/libs/moment';
import '@/libs/tour';
import '@/libs/google-map';
import '@/libs/calender';
import '@/libs/firebase';
import '@/libs/vue-apexcharts';
import '@/libs/vue-dragscroll';
import 'wameed-ui/dist/index.css';
import '@/libs/vue-cropper';

// Axios Mock Adapter
// import '@/@fake-db/db'
import "echarts";

import ECharts from 'vue-echarts'
import {use} from 'echarts/core'

// import ECharts modules manually to reduce bundle size
import {CanvasRenderer} from 'echarts/renderers'
import {BarChart, PieChart} from 'echarts/charts'
import {TitleComponent, GridComponent, TooltipComponent, LegendComponent} from 'echarts/components'

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    GridComponent,
    TooltipComponent, TitleComponent, LegendComponent
]);

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

String.prototype.ArtoEn = function () {
    return this.replace(/[\u0660-\u0669]/g,
        d => d.charCodeAt() - 1632)
}



String.prototype.AMPM = function () {
    return this.replace('ص', 'صباحاً').replace('م', 'مساءً');
}


Vue.directive('permission', permission)



Vue.mixin({
    methods: {
        formatNumber: function (number, is_price = true) {
            if(!number)
                number=0;
            let value = Intl.NumberFormat('en').format(number);
            if (is_price) {
                return value + ' ' + this.$t('common.rial');
            }
            return value;
        },


        formatDate: function (date, format = 'hh:mm A - YYYY/MM/DD') {
            return moment(date).format(format).ArtoEn().AMPM();
        },


    },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
// require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// require('@core/assets/custom.scss') // For form-wizard
// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');
Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);


// Composition API
Vue.use(VueCompositionAPI);


new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
