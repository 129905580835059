export default {
    vendorsList:[],
    servicesList:[],
    topClientsVendor:[],
    data: {
        'orders': {
            "xAxis_data": [],
            "series": []
        },
        'services': {
            "src": [],
            "series": []
        },
        'offers': {
            "xAxis_data": [],
            "series": []
        },
        'drivers': {
            "drivers": [],
            "total": 0
        },
        'users': {
            "series": []
        },
        'vendors': {
            "vendors": [],
            "total": 0
        },
        'top_products_by_vendor': {
            "products": [],
            "total": 0
        },
        'top_clients': {
            "clients": [],
            "total": 0
        },
    },
};
