export default {
  data: [],
  detail:{},
  total: {
    totalItems: 5,
    totalPages: 5
  },
  vendors: [],
  vendorTypes: [],

  redirects: []

};
