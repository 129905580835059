export default {
  categories: [],
  data: [],
  products: [],
  productDetail: {},
  total: {
    totalItems: 5,
    totalPages: 5
  },

  detail: {}

};
