export default {
  /**
   * Fetches branches data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`branches${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the status of a branch on the server and commits the updated branch data to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`branches/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new branch on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`branches/create`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        if (error.response.status === 402) {
          commit('failMessage', 'service_fee_exits', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates an existing branch on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`branches/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {


        if (error.response.status === 402) {
          commit('failMessage', 'service_fee_exits', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a branch from the server and removes it from the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`branches/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

};
