export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },
  getVehicleTypes(state) {
    return state.vehicleTypes;
  },
  getAddressTypes(state) {
    return state.addressTypes;
  },
  getPlatformBoundaries(state) {
    return state.platformBoundaries;
  },
  getVendorCategories(state) {
    return state.vendorCategories;
  },
  getVendorClassification(state) {
    return state.vendorClassification;
  },


  getDetail(state) {
    return state.simpleDetail;
  },
  getBranches(state) {
    return state.branches;
  },

};
