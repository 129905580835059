import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP,
    libraries: 'places,drawing',
    region: 'AR',
    language: 'ar',
  },
});
