export default {

  /**
   * Fetches general settings from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async get({ commit }) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`general-settings`)
      .then((response) => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches vendors from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getVendors({ commit }) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`general-settings/get-vendors`)
      .then((response) => {
        if (response.status === 200) {
          commit('setVendors', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Resets the data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async resetData({ commit }) {
    commit('setData', null);
  },

  /**
   * Fetches vendor products from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {number} id - The id of the vendor.
   */
  async getVendorProducts({ commit }, id) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`general-settings/${id}/get-vendors-products`)
      .then((response) => {
        if (response.status === 200) {
          commit('setVendorProducts', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Sends a recommended meal to the server.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async recommendedMeal({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`general-settings/recommended-meal`, data)
      .then((response) => {
        if (response.status === 200) {
          commit('setVendorProducts', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
