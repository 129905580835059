import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  /**
   * Fetches discounts data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`discounts${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches detailed discount data for a specific discount from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the discount to fetch.
   */
  async detail({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`discounts/${id}`)
      .then(response => {
        if (response.status === 200) {
          commit('setDetail', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches vendor products data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor to fetch products for.
   */
  async vendorProducts({ commit }, id) {

    await this._vm.$http
      .get(`discounts/vendor_products/${id}`)
      .then(response => {
        if (response.status === 200) {
          let data = [{
            id: 'all',
            label: i18n.t('discounts.form.all_products'),
            children: response.data.data
          }];
          commit('setProducts', data);
        }
      })
      .catch(error => {
        throw error;
      });
  },


  /**
   * Resets the products data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async resetProducts({ commit }) {

    commit('setProducts', null);

  },


  /**
   * Fetches discount info for a specific discount from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the discount to fetch info for.
   */
  async discountInfo({ commit }, id) {
    commit('loadingStart', null, { root: true });
    commit('unsetDiscountInfo');

    await this._vm.$http
      .get(`discounts/${id}/info`)
      .then(response => {
        if (response.status === 200) {
          commit('setDiscountInfo', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Fetches discount usage data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async discountUsage({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`discounts/${data.id}/usage${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setDiscountUsage', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Unsets the detail data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async unsetDetail({ commit }) {
    commit('unsetDetail');
  },

  /**
   * Toggles the status of a discount on the server and commits the updated discount data to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`discounts/toggle`, data)
      .then(response => {
        if (response.status === 200) {

          commit('updateData', response.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new discount on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post('discounts/create', data)
      .then(response => {
        if (response.status === 200) {
          router.push({ name: 'discounts', params: { lang: i18n.locale } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Updates an existing discount on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`discounts/update/${data.id}`, data)
      .then(response => {
        if (response.status === 200) {
          router.push({ name: 'discounts', params: { lang: i18n.locale } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Deletes a discount from the server and removes it from the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the discount to delete.
   */
  async delete({ commit }, id) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`discounts/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', id);
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Fetches discount on data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getDiscountOn({ commit }) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`discounts/ons`)
      .then(response => {
        if (response.status === 200) {
          commit('setDiscountOns', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Fetches users data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getUsers({ commit }) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`discounts/users`)
      .then(response => {
        if (response.status === 200) {
          commit('setUsers', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Fetches conditions data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getConditions({ commit }) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`discounts/conditions`)
      .then(response => {
        if (response.status === 200) {
          commit('setConditions', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches apply for data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getApplyFor({ commit }) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`discounts/apply_for`)
      .then(response => {
        if (response.status === 200) {
          commit('setApplyFor', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
