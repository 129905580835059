export default {
  data: [],
  total: {
    totalItems: 5,
    totalPages: 5
  },
  vendors: [],

  categories: []

};
