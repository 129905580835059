export default {
  /**
   * Fetches ads data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`ads${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches detailed ad data for a specific ad from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the ad to fetch.
   */
  async getDetail({ commit }, id) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`ads/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the status of an ad on the server and commits the updated ad data to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`ads/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new ad on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`ads/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates an existing ad on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`ads/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes an ad from the server and removes it from the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`ads/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches vendor data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} type - The type of vendors to fetch.
   */
  async getVendors({ commit },type) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`ads/dropdown/vendors/${type}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setVendors', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Resets the vendors data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async resetVendors({ commit }) {
    commit('setVendors', []);
  },

  /**
   * Fetches vendor type data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getVendorTypes({ commit }) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`ads/dropdown/vendor-types`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setVendorTypes', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches product data for a specific vendor from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor to fetch products for.
   */
  async getProducts({ commit }, id) {
    commit('deleteRedirects');
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`ads/dropdown/${id}/products`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setRedirects', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches package data for a specific vendor from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor to fetch packages for.
   */
  async getPackages({ commit }, id) {
    commit('deleteRedirects');
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`ads/dropdown/${id}/packages`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setRedirects', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
