const permissions = {
  readHome: 'read home',
  readOrders: 'read orders',
  editOrders: 'edit orders',


  readOrdersStore: 'read orders store',
  editOrdersStore: 'edit orders store',
  readOrdersMedicine: 'read orders medicine',
  editOrdersMedicine: 'edit orders medicine',
  readOrdersRestaurants: 'read orders restaurants',
  editOrdersRestaurants: 'edit orders restaurants',
  readOrdersDelivery: 'read orders delivery',
  editOrdersDelivery: 'edit orders delivery',

  readAds: 'read ads',
  addAds: 'add ads',
  editAds: 'edit ads',
  deleteAds: 'delete ads',
  // featred stores
  readFeaturedStores: 'read featured stores',
  addFeaturedStores: 'add featured stores',
  editFeaturedStores: 'edit featured stores',
  deleteFeaturedStores: 'delete featured stores',
  // featred medicine
  readFeaturedMedicine: 'read featured medicine',
  addFeaturedMedicine: 'add featured medicine',
  editFeaturedMedicine: 'edit featured medicine',
  deleteFeaturedMedicine: 'delete featured medicine',

  readFeaturedRestaurants: 'read featured restaurants',
  addFeaturedRestaurants: 'add featured restaurants',
  editFeaturedRestaurants: 'edit featured restaurants',
  deleteFeaturedRestaurants: 'delete featured restaurants',


  readClientsInfo: 'read clients info',
  readClientsOrders: 'read clients orders',
  addClients: 'add clients',
  editClients: 'edit clients',
  readCompaniesInfo: 'read companies info',
  readCompaniesOrders: 'read companies orders',
  readCompaniesProducts: 'read companies products',
  addCompaniesProducts: 'add companies products',
  editCompaniesProducts: 'edit companies products',
  deleteCompaniesProducts: 'delete companies products',
  readCompaniesCategories: 'read companies categories',
  addCompaniesCategories: 'add companies categories',
  editCompaniesCategories: 'edit companies categories',
  deleteCompaniesCategories: 'delete companies categories',
  readCompaniesFinancials: 'read companies financials',
  readCompaniesReviews: 'read companies reviews',
  addCompanies: 'add companies',
  editCompanies: 'edit companies',
  readDriversInfo: 'read drivers info',
  readDriversOrders: 'read drivers orders',
  readDriversFinancials: 'read drivers financials',
  readDriversReviews: 'read drivers reviews',
  editDrivers: 'edit drivers',
  addDrivers: 'add drivers',

  readFinancials: 'read financials',
  readStatements: 'read statements',

  readDiscounts: 'read discounts',
  addDiscounts: 'add discounts',
  editDiscounts: 'edit discounts',
  deleteDiscounts: 'delete discounts',
  readServiceFees: 'read service fees',
  addServiceFees: 'add service fees',
  editServiceFees: 'edit service fees',
  deleteServiceFees: 'delete service fees',
  readReviews: 'read reviews',
  editReviews: 'edit reviews',
  deleteReviews: 'delete reviews',
  readWorkDays: 'read work days',
  addWorkDays: 'add work days',
  editWorkDays: 'edit work days',
  deleteWorkDays: 'delete work days',
  readVehicleTypes: 'read vehicle types',
  addVehicleTypes: 'add vehicle types',
  editVehicleTypes: 'edit vehicle types',
  deleteVehicleTypes: 'delete vehicle types',

  readOrderTypes: 'read order types',
  addOrderTypes: 'add order types',
  editOrderTypes: 'edit order types',
  deleteOrderTypes: 'delete order types',

  readAdminUsers: 'read admin users',
  addAdminUsers: 'add admin users',
  editAdminUsers: 'edit admin users',
  deleteAdminUsers: 'delete admin users',
  readRoles: 'read roles',
  addRoles: 'add roles',
  editRoles: 'edit roles',
  deleteRoles: 'delete roles',
  contacts_settings: 'contacts (settings)',
  pages_settings: 'pages (settings)',
  boundary_settings: 'boundary (settings)',
  sendNotifications_settings: 'send notifications (settings)',
  general_settings: 'general (settings)',
  readPaymentGetaways_settings: 'read payment getaways (settings)',
  updatePaymentGetaways_settings: 'update payment getaways (settings)',
  chat: 'chat',


  readClassifications: 'read classifications',
  addClassifications: 'add classifications',
  editClassifications: 'edit classifications',
  deleteClassifications: 'delete classifications',

  readAppMessages: 'read app messages',
  addAppMessages: 'add app messages',
  editAppMessages: 'edit app messages',
  deleteAppMessages: 'delete app messages',

  readServiceDetail: 'read service detail',
  addServiceDetail: 'add service detail',
  editServiceDetail: 'edit service detail',
  deleteServiceDetail: 'delete service detail',


  readBranches: 'read branches',
  addBranches: 'add branches',
  editBranches: 'edit branches',
  deleteBranches: 'delete branches',

  readLogs: 'read logs',


  readAssignedOrders: 'read assigned orders',
  assignOrders: 'assign orders',
  assignSelfToOrders: 'assign self to orders'
};

export { permissions };
